import { format, isPast } from "date-fns";

/**
 * Opportunity Model Helper – enriching data to template representation.
 * @param target – Game opportunity
 */
export default (target) => {
  // eslint-disable-next-line
  console.log("Enriching Opportunity. \ntarget: ", target);

  const _passThrough = (opportunity) => {
    if (Array.isArray(opportunity)) {
      return opportunity.map((o) => _passThrough(o));
    }

    opportunity.startStr = format(opportunity?.start, "eeee dd.MM.yyyy");
    opportunity.intervalStr = `${format(opportunity.start, "HH:mm")} – ${format(opportunity.end, "HH:mm")}`;
    opportunity.mode.name = useUpperCase(opportunity?.mode?.name);

    // show the number for Rioters that joined the opportunity,
    // it will help better understand if there are still slots left
    const pCount = useSize(
      useFilter(opportunity?.participants, (p) => p.accepted),
    );
    opportunity.participantsPlural = `${pCount} confirmed`;

    // enrich participants user
    useMap(opportunity?.participants, (p) => (p.user = enrichUser(p.user)));

    opportunity.isPast = isPast(opportunity?.end);

    opportunity.goToDetailPage = async () =>
      await navigateTo(`/game-opportunities/${opportunity.id}`);

    // eslint-disable-next-line
    console.log("Enriching Opportunity Result:\n", opportunity);
    return opportunity;
  };

  return _passThrough(target);
};
